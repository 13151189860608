import { ENV, getGraphqlHeaders } from '@laguna/aws';
import { delay } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
import { makeAutoObservable } from 'mobx';
import Pusher, { Channel } from 'pusher-js/with-encryption';
import { fetchData } from './fetchData';
import { queryClient } from './getQueryResult';
import { getHash } from './queryUtils';
import {
  PusherMessages,
  SocketType,
  getDataDecryptWrapper,
  handleAlertSocketMessage,
  handleEventSocketMessages,
  handleRelaySocketMessages,
  handleSubscriptionSocketMessage,
  handleTranscriptionSocketMessage,
} from './socketUtils';

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000;
const CONNECTION_TIMEOUT = 10000;

async function tryToConnect(pusher: Pusher) {
  await new Promise<void>((resolve, reject) => {
    const timeoutId = setTimeout(() => reject(new Error('Connection timeout')), CONNECTION_TIMEOUT);

    const clearAll = () => {
      clearTimeout(timeoutId);
      pusher.connection.unbind('error', rejectPromise);
      pusher.connection.unbind('connected', resolve);
    };

    const rejectPromise = (error: any) => {
      logger.error('Failed to connect to pusher', { error });
      clearAll();
      reject(error);
    };

    const resolvePromise = () => {
      clearAll();
      resolve();
    };

    pusher.connection.bind('connected', resolvePromise);
    pusher.connection.bind('error', rejectPromise); // Bind error event
  });
}

function bindToErrorEvents(pusher: Pusher) {
  ['pusher:error', 'error'].forEach((event) => {
    pusher.bind(event, (error: any) => {
      logger.error(`Pusher error (${event})`, { error });
    });
  });
}

async function instantiatePusher(userId: string) {
  const headers = await getGraphqlHeaders();
  const endpoint = ENV.NX_GQL_ENDPOINT + '/secured/webhooks/pusher/';
  const appKey = ENV.NX_PUSHER_API_KEY;
  const pusher = new Pusher(appKey, {
    cluster: process.env.NX_REACT_APP_ENV === 'highmark' ? 'ap2' : 'mt1',
    channelAuthorization: {
      transport: 'ajax',
      endpoint: endpoint + 'auth',
      headers,
    },
    userAuthentication: {
      transport: 'ajax',
      endpoint: endpoint + 'user-auth',
      headers,
    },
  });
  pusher.signin();
  return pusher;
}

export const connectToPusher = async (
  userId: string,
  maxRetries: number = MAX_RETRIES,
  retryDelay: number = RETRY_DELAY
): Promise<{ pusher: Pusher; userChannel: Channel } | null> => {
  let attempt = 0;

  const attemptConnection = async (): Promise<{ pusher: Pusher; userChannel: Channel } | null> => {
    try {
      const pusher = await instantiatePusher(userId);
      await tryToConnect(pusher);
      bindToErrorEvents(pusher);

      const userChannel = pusher.subscribe(`private-encrypted-${userId}`);
      return { pusher, userChannel };
    } catch (error) {
      logger.error('Failed to connect to pusher', { error });
      return null;
    }
  };

  while (attempt < maxRetries) {
    const result = await attemptConnection();
    if (result) {
      return result;
    }

    attempt += 1;
    logger.warn(`Retrying connection to Pusher... Attempt ${attempt} of ${maxRetries}`);
    await delay(retryDelay);
  }

  logger.error('Exceeded maximum retry attempts to connect to Pusher');
  return null;
};

const UnsubscribeDocument = `
    mutation unsubscribe($unsubscribeParams: UnsubscribeParams!) {
  unsubscribe(unsubscribeParams: $unsubscribeParams)
}
    `;

const unsubscribeMutation = (variables: any) =>
  fetchData(UnsubscribeDocument, variables, undefined, ['unsubscribe', variables]);

class SocketStore {
  private _pusher?: any = undefined;
  socketsError = false;
  initialized = false;
  constructor() {
    makeAutoObservable(this);
  }

  get socketId() {
    return this._pusher?.connection.socket_id;
  }

  setError = (hasError: boolean) => {
    this.socketsError = hasError;
  };

  initPusher = async (
    userId: string,
    onTranscriptionEvent: (event: PusherMessages) => void,
    shouldDecrypt: boolean
  ) => {
    const pusherConnection = await connectToPusher(userId);
    if (!pusherConnection) {
      this.setError(true);
      return;
    }
    const { pusher, userChannel } = pusherConnection;

    const subscriptionChannel = pusher.subscribe(`private-encrypted-${userId}-${pusher.connection.socket_id}`);
    this.handleUnsubscribeRegistration();
    const wrapper = getDataDecryptWrapper(userId, onTranscriptionEvent, shouldDecrypt);

    userChannel.bind(SocketType.alert, wrapper(handleAlertSocketMessage));
    userChannel.bind(SocketType.transcription, wrapper(handleTranscriptionSocketMessage));
    userChannel.bind(SocketType.relay, wrapper(handleRelaySocketMessages));
    userChannel.bind(SocketType.event, wrapper(handleEventSocketMessages));
    subscriptionChannel.bind(SocketType.subscription, handleSubscriptionSocketMessage);

    this._pusher = pusher;
    this.initialized = true;
  };

  handleUnsubscribeRegistration = () => {
    const queryCache = queryClient.getQueryCache();
    if (queryCache.hasListeners()) {
      return;
    }
    queryCache.subscribe(async (event) => {
      if (event.type === 'removed') {
        const subscriptionKey = getHash(event.query.queryKey);
        const socketId = this.socketId;
        if (subscriptionKey && socketId) {
          logger.debug('unsubscribe', { subscriptionKey, socketId });
          const fetchPromise = await unsubscribeMutation({
            unsubscribeParams: { subscriptionKey, socketId },
          });
          try {
            await fetchPromise();
          } catch (error) {
            logger.debug('fail to unsubscribe from channel', { subscriptionKey, socketId, error });
          }
        }
      }
    });
  };

  cleanup = () => {
    logger.debug('socket cleanup was called');
    this.setError(false);
    if (this._pusher) {
      logger.debug('socket calling disconnect');
      this._pusher.disconnect();
      logger.debug('socket disconnect called');
      this._pusher = undefined;
    }
  };
}

export const socketStore = new SocketStore();

export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const US_DATE_FORMAT_SHORT = 'MM/dd/yy';
export const SERVER_DATE_FORMAT = 'yyyy/MM/dd';
export const HOUR_FORMAT = 'HH:mm';
export const HOUR_FORMAT_AM_PM = 'p';
export const SHORT_DATE_FORMAT = 'MMM do';
export const DAY_FORMAT = 'M/d';
export const DAY_NAME = 'EEE';
export const FULL_HOUR_FORMAT = 'HH:mm:ss';
export const US_DATE_TIME_FORMAT = `${US_DATE_FORMAT} ${HOUR_FORMAT_AM_PM}`;

export const SECOND_MS = 1000;
export const MINUTE = SECOND_MS * 60;
export const SECONDS_IN_HOUR = 60 * 60;
export const HOUR = SECONDS_IN_HOUR * SECOND_MS;
export const APPOINTMENT_DURATIONS = [5, 10, 15, 20, 30];
export const AUTO_LOGOUT_DURATION_IN_MINUTES = 45 * MINUTE;
export const QUERY_CACHE_IDLE_TIME = 15 * MINUTE;
export const ONE_DAY = HOUR * 24;

export const SELECTED_ENDPOINT = 'selectedEndpoint';

export const LAGUNA_APP_LINK = 'https://links.lagunahealth.com/app';

import { startCase, capitalize } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const v4 = () => uuidV4();
export const capitalizeCamelCase = (camelCaseStr = '') =>
  camelCaseStr ? capitalize(startCase(camelCaseStr).toLowerCase()) : '';

export const getTextSafe = (text?: string, withNewRow = false) => {
  if (!text) return '';
  return `${text}${withNewRow ? '\n' : ''}`;
};

export const cleanWhiteSpaces = (str: string) => str.replace(/^\s+|\s+$/g, '');

export const safeParseJson = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

export const awaitWithTimeout = (timeout: number, callback: () => Promise<any>) => {
  const timeOut = () => {
    return new Promise((res, rej) => setTimeout(rej, timeout, new Error(`Timed out after ${timeout}ms`)));
  };
  try {
    return Promise.race([callback(), timeOut()]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return;
};

import { SvgIcon, SvgIconProps } from '@mui/material';
import { IconVariants as Variants, IconComponents } from './Icons';
import React, { Suspense } from 'react';

export type IconVariants = Variants;

interface LagunaIconProps extends SvgIconProps {
  variant: IconVariants;
}

export const LagunaIcon: React.FC<LagunaIconProps> = ({ variant, width, height, ...props }) => {
  const Icon = IconComponents[variant];
  if (!Icon) {
    console.error('Icon not found for variant:', variant);
    return null;
  }
  return (
    <SvgIcon inheritViewBox color='inherit' {...props}>
      <Suspense fallback={<span data-testid='loading-icon' />}>
        <Icon width={width} height={height} {...props} />
      </Suspense>
    </SvgIcon>
  );
};

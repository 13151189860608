import { LagunaIcon } from '@laguna/Icons';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { getHash } from '../queryUtils';
import { handleSubscriptionSocketMessage } from './handleSubscriptionSocketMessage';
import { AlertSocketMessage, SocketAlertEvents, SocketHandler } from './types';

const HIDDEN_ALERTS = [
  'transcriptSummaryInsightsCompleted',
  'transcriptBarrierExtractionCompleted',
  'transcriptProcessingStarted',
];

export const toastEventsTarget = new EventTarget();

export const handleAlertSocketMessage: SocketHandler = async (data: AlertSocketMessage, onTranscriptionEvent) => {
  if (data.payload.toast && !HIDDEN_ALERTS.includes(data.payload.type)) {
    toastEventsTarget.dispatchEvent(new CustomEvent('alert', { detail: data.payload }));
    const key = enqueueSnackbar(data.payload.text, {
      variant: 'success',
      hideIconVariant: true,
      autoHideDuration: 10000,
      showClose: true,
      icon: <LagunaIcon variant='automatedSummaries' />,
      SnackbarProps: {
        onClick: () => {
          onTranscriptionEvent({ name: SocketAlertEvents.NAVIGATION, payload: data.payload });
          closeSnackbar(key);
        },
        style: { cursor: 'pointer' },
      },
    } as any);
  }
  const metadata = { ...data.metadata, subscriptionKey: getHash(['getAlerts', {}]) };
  handleSubscriptionSocketMessage({ ...data, metadata }, onTranscriptionEvent);
};

import { Logger, LoggerModule } from './types';
import { mockLogger, isProd } from './consts';
import * as webLogger from './targets/webLogger';

const loggerModule: LoggerModule = isProd ? webLogger : mockLogger;
loggerModule.init();

const logger = { ...loggerModule.logger };
const init = () => {
  const LOG_LEVELS: (keyof Logger)[] = ['log', 'debug', 'info', 'warn', 'error'];
  for (const level of LOG_LEVELS) {
    // @ts-ignore override base function
    logger[level] = (message: string, messageContext?: object) => {
      const context = isProd ? { __EVENT__: messageContext || { empty: true } } : messageContext;
      // @ts-ignore override base function
      loggerModule.logger[level](message, context);
    };
  }
};

init();

const addLoggerContext = loggerModule.addLoggerContext;
export { logger, addLoggerContext };

addLoggerContext({
  service: process.env.NX_TASK_TARGET_PROJECT,
});

import { ENV } from '@laguna/aws';
import { cleanWhiteSpaces } from '@laguna/common/utils/general';
import { logger } from '@laguna/logger';
import { QueryKey } from '@tanstack/react-query';
import { generateClient } from 'aws-amplify/api';
import { getHash } from './queryUtils';
import { socketStore } from './socketStore';
const client = generateClient();

const isNetworkError = (error: any) => error?.errors?.length === 1 && error.errors[0].message === 'Network error';

export class NetworkError extends Error {
  constructor() {
    super('Network Error');
    this.name = 'NetworkError';
  }
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
  key?: QueryKey
) => {
  return async () => {
    const subKey = getHash(key);
    const headers = { ...(options || {}) } as Record<string, string>;

    if (socketStore.initialized) {
      const isMutation = cleanWhiteSpaces(query).startsWith('mutation');
      if (!isMutation && subKey) {
        headers['sub-key'] = subKey;
        headers['sub-socket-id'] = socketStore.socketId;
      }
    }

    if (ENV.NX_LOCAL_TOKEN) {
      headers['Authorization'] = ENV.NX_LOCAL_TOKEN;
    }

    const startTime = Date.now();
    try {
      const res = await client.graphql(
        {
          query,
          variables: variables as any,
        },
        headers
      );
      const duration = Date.now() - startTime;
      logger.debug('graphqlRequestSend', { queryKey: key, variables, duration });
      return (res as any).data as TData;
    } catch (error: any) {
      const duration = Date.now() - startTime;
      const networkError = isNetworkError(error);
      logger.error('graphqlRequestSend', {
        queryKey: key,
        variables,
        error: error.message,
        duration,
        networkError,
      });
      if (networkError) {
        throw new NetworkError();
      } else {
        throw error;
      }
    }
  };
};

import { LoggerModule } from './types';
const noop = () => null;

export const mockLogger: LoggerModule = {
  logger: {
    ...console,
    debug: noop,
    addContext: noop,
    setContext: noop,
    removeContext: noop,
    setLevel: noop,
  },
  addLoggerContext: noop,
  init: noop,
};
const getIsProd = () => {
  if (process.env.NODE_ENV === 'test') {
    return false;
  }
  if (process.env.NX_WEB) {
    return process.env.NODE_ENV === 'production';
  }
  // @ts-ignore __DEV__ not exiting for web
  return !global.__DEV__;
};
export const isProd = getIsProd();


/// <reference types="vite-plugin-svgr/client" />
import React from 'react';

/**
 * THIS FILE WAS GENERATED USING "yarn updateIcons"
 * DON'T CHANGE IT MANUALLY,
 * to update icons, remove/ add svg files to libs/resources/images/icons then re-run "yarn updateIcons"
 * */

export const Access = React.lazy(() => import('@laguna/resources/icons/access.svg?react'));
export const AddPerson = React.lazy(() => import('@laguna/resources/icons/addPerson.svg?react'));
export const Admission = React.lazy(() => import('@laguna/resources/icons/admission.svg?react'));
export const AppActivity = React.lazy(() => import('@laguna/resources/icons/appActivity.svg?react'));
export const AppLink = React.lazy(() => import('@laguna/resources/icons/appLink.svg?react'));
export const Article = React.lazy(() => import('@laguna/resources/icons/article.svg?react'));
export const Attachment = React.lazy(() => import('@laguna/resources/icons/attachment.svg?react'));
export const Attribute = React.lazy(() => import('@laguna/resources/icons/attribute.svg?react'));
export const AutomatedSummaries = React.lazy(() => import('@laguna/resources/icons/automatedSummaries.svg?react'));
export const Barrier = React.lazy(() => import('@laguna/resources/icons/barrier.svg?react'));
export const Barriers = React.lazy(() => import('@laguna/resources/icons/barriers.svg?react'));
export const Burnout = React.lazy(() => import('@laguna/resources/icons/burnout.svg?react'));
export const Calls = React.lazy(() => import('@laguna/resources/icons/calls.svg?react'));
export const Care = React.lazy(() => import('@laguna/resources/icons/care.svg?react'));
export const Chat = React.lazy(() => import('@laguna/resources/icons/chat.svg?react'));
export const ChatIn = React.lazy(() => import('@laguna/resources/icons/chatIn.svg?react'));
export const ChatOut = React.lazy(() => import('@laguna/resources/icons/chatOut.svg?react'));
export const Diversity = React.lazy(() => import('@laguna/resources/icons/diversity.svg?react'));
export const Face = React.lazy(() => import('@laguna/resources/icons/face.svg?react'));
export const HealthLiteracy = React.lazy(() => import('@laguna/resources/icons/healthLiteracy.svg?react'));
export const Home = React.lazy(() => import('@laguna/resources/icons/home.svg?react'));
export const InfoTab = React.lazy(() => import('@laguna/resources/icons/info_tab.svg?react'));
export const Lifestyle = React.lazy(() => import('@laguna/resources/icons/lifestyle.svg?react'));
export const List = React.lazy(() => import('@laguna/resources/icons/list.svg?react'));
export const Medical = React.lazy(() => import('@laguna/resources/icons/medical.svg?react'));
export const MemberCreated = React.lazy(() => import('@laguna/resources/icons/memberCreated.svg?react'));
export const Petal = React.lazy(() => import('@laguna/resources/icons/petal.svg?react'));
export const Phone = React.lazy(() => import('@laguna/resources/icons/phone.svg?react'));
export const Questionnaire = React.lazy(() => import('@laguna/resources/icons/questionnaire.svg?react'));
export const QuestionnaireForm = React.lazy(() => import('@laguna/resources/icons/questionnaireForm.svg?react'));
export const Safety = React.lazy(() => import('@laguna/resources/icons/safety.svg?react'));
export const Sdoh = React.lazy(() => import('@laguna/resources/icons/sdoh.svg?react'));
export const SessionPhone = React.lazy(() => import('@laguna/resources/icons/sessionPhone.svg?react'));
export const SmartPad = React.lazy(() => import('@laguna/resources/icons/smartPad.svg?react'));
export const SortAsc = React.lazy(() => import('@laguna/resources/icons/sortAsc.svg?react'));
export const SortDesc = React.lazy(() => import('@laguna/resources/icons/sortDesc.svg?react'));
export const StatusUpdated = React.lazy(() => import('@laguna/resources/icons/statusUpdated.svg?react'));
export const Support = React.lazy(() => import('@laguna/resources/icons/support.svg?react'));
export const TopMetrics = React.lazy(() => import('@laguna/resources/icons/topMetrics.svg?react'));
export const Transcript = React.lazy(() => import('@laguna/resources/icons/transcript.svg?react'));

export const IconComponents = {
    access: Access,
    addPerson: AddPerson,
    admission: Admission,
    appActivity: AppActivity,
    appLink: AppLink,
    article: Article,
    attachment: Attachment,
    attribute: Attribute,
    automatedSummaries: AutomatedSummaries,
    barrier: Barrier,
    barriers: Barriers,
    burnout: Burnout,
    calls: Calls,
    care: Care,
    chat: Chat,
    chatIn: ChatIn,
    chatOut: ChatOut,
    diversity: Diversity,
    face: Face,
    healthLiteracy: HealthLiteracy,
    home: Home,
    info_tab: InfoTab,
    lifestyle: Lifestyle,
    list: List,
    medical: Medical,
    memberCreated: MemberCreated,
    petal: Petal,
    phone: Phone,
    questionnaire: Questionnaire,
    questionnaireForm: QuestionnaireForm,
    safety: Safety,
    sdoh: Sdoh,
    sessionPhone: SessionPhone,
    smartPad: SmartPad,
    sortAsc: SortAsc,
    sortDesc: SortDesc,
    statusUpdated: StatusUpdated,
    support: Support,
    topMetrics: TopMetrics,
    transcript: Transcript
} as const;

export type IconVariants = keyof typeof IconComponents;

